import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable fp/no-this */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDateFormatter } from "../../../i18n";
import { makeStyles, useTheme } from "@material-ui/core";
import { renderToString } from "react-dom/server";
import { useHistory } from "react-router-dom";
import "highcharts/modules/accessibility";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        background: theme.palette.grey[100],
        alignItems: "center",
        boxShadow: theme.shadows[5],
    },
    percentage: {
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        fontVariantNumeric: "tabular-nums",
    },
    textContainer: {
        background: "#FFF",
        padding: theme.spacing(1, 2),
    },
    urlCountText: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
    },
}));
export function LargeHealthScoreTrend(props) {
    var _a, _b, _c;
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const formatDate = useDateFormatter();
    const isSingleDataPoint = props.healthScoreTrend.length === 1;
    const options = {
        exporting: {
            enabled: false,
        },
        chart: {
            spacing: [70, 0, 0, 0],
            type: "spline",
            shadow: false,
            style: {
                fontSize: "1em",
                fontFamily: theme.typography.fontFamily,
            },
        },
        xAxis: {
            type: "datetime",
            lineColor: theme.palette.grey[100],
            gridLineColor: theme.palette.grey[100],
            // We always want to show the date, not time of the day.
            dateTimeLabelFormats: {
                month: "%b %e",
                day: "%b %e",
                hour: "%b %e",
                minute: "%b %e",
                week: "%b %e",
                millisecond: "%b %e",
                second: "%b %e",
            },
            labels: {
                style: {
                    fontSize: "13px",
                    fontWeight: "400",
                    color: theme.palette.text.primary,
                },
            },
            title: {
                style: {
                    color: theme.palette.text.primary,
                },
            },
        },
        yAxis: {
            title: undefined,
            endOnTick: false,
            min: 0,
            // In theory, 100 is the max health score.
            // 100.5 is here to make sure that the line is not cut off
            // when the health score is 100.
            max: 100.5,
            lineColor: theme.palette.grey[100],
            gridLineColor: theme.palette.grey[100],
            labels: {
                style: {
                    fontSize: "13px",
                    fontWeight: "400",
                    color: theme.palette.text.primary,
                },
            },
        },
        legend: {
            rtl: true,
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            itemMarginBottom: 5,
            floating: true,
            y: -60,
            useHTML: true,
            itemStyle: {
                fontSize: "13px",
                fontWeight: "400",
                color: theme.palette.text.primary,
            },
        },
        plotOptions: {
            series: {
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                },
            },
        },
        title: undefined,
        credits: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            outside: true,
            shape: "rect",
            padding: 0,
            borderRadius: 0,
            borderWidth: 0,
            shadow: false,
            style: {
                fontSize: "1em",
                lineHeight: "1.5em",
                color: theme.palette.text.primary,
                // Making sure that sidebar next to the chart
                // doesn't cut the tooltip off.
                zIndex: theme.zIndex.drawer + 1,
            },
            formatter: function () {
                return renderToString(_jsx("div", { className: classes.container, children: _jsxs("div", { className: classes.textContainer, children: [_jsx("div", { className: classes.urlCountText, children: props.getTooltipScoreText(this.y || 0) }), _jsx("div", { children: formatDate(typeof this.x === "string" ? new Date(this.x) : this.x, { dateStyle: "long", timeStyle: "short" }) }), _jsx("div", { children: props.getScoreCategoryText(props.categoryName) })] }) }));
            },
        },
        series: [],
    };
    // If a trend has only one data point, we want to show a line on the same
    // level as the marker. This can be achieved by adding additional
    // serie to the array that has fake data points. One is before
    // the real data point, and the other one is after.
    // This way also makes it easy to modify interaction with the line.
    if (isSingleDataPoint) {
        // eslint-disable-next-line fp/no-mutating-methods
        (_a = options.series) === null || _a === void 0 ? void 0 : _a.push({
            id: "health-score-trend-single-data-point",
            type: "area",
            lineWidth: 3,
            dataLabels: { enabled: false },
            color: theme.palette.healthScoreChart.color,
            fillColor: theme.palette.healthScoreChart.fillColor,
            marker: { enabled: false },
            label: {
                enabled: false,
            },
            showInLegend: false,
            enableMouseTracking: false,
            data: [
                {
                    x: new Date(props.healthScoreTrend[0].dateTime).getTime() - 1,
                    y: Math.floor(props.healthScoreTrend[0].value * 100),
                },
                {
                    x: new Date(props.healthScoreTrend[0].dateTime).getTime() + 1,
                    y: Math.floor(props.healthScoreTrend[0].value * 100),
                },
            ],
        });
    }
    // eslint-disable-next-line fp/no-mutating-methods
    (_b = options.series) === null || _b === void 0 ? void 0 : _b.push({
        id: "health-score-trend",
        name: props.getScoreCategoryText(props.categoryName),
        type: "area",
        lineWidth: 3,
        dataLabels: { enabled: false },
        color: theme.palette.healthScoreChart.color,
        fillColor: theme.palette.healthScoreChart.fillColor,
        point: {
            events: {
                click: (event) => {
                    var _a;
                    const point = ((_a = event.point) !== null && _a !== void 0 ? _a : event.target);
                    const url = getUrlFromPoint(point);
                    if (!url) {
                        return;
                    }
                    if (event.ctrlKey || event.metaKey) {
                        window.open(url, "_blank", "noreferrer");
                    }
                    else {
                        history.push(url);
                    }
                },
            },
        },
        marker: {
            symbol: "circle",
            radius: isSingleDataPoint ? 6 : 4,
            enabled: true,
        },
        data: props.healthScoreTrend
            .filter((point) => !!point)
            .map((t) => {
            return {
                url: props.getTrendDataPointUrl(t),
                x: t.dateTime,
                y: Math.floor(t.value * 100),
            };
        }),
    });
    const industryBenchmark = props.industryBenchmark;
    // Industry benchmark dash line is a faked serie using
    // date time from the original series.
    if (props.industryCode && typeof industryBenchmark === "number") {
        // eslint-disable-next-line fp/no-mutating-methods
        (_c = options.series) === null || _c === void 0 ? void 0 : _c.push({
            id: "industry-benchmark",
            name: props.getIndustryBenchmarkLegendText(props.industryCode.replaceAll("_", " ")),
            lineWidth: 2,
            type: "line",
            dataLabels: { enabled: false },
            color: theme.palette.grey[700],
            label: {
                enabled: false,
            },
            enableMouseTracking: false,
            marker: {
                enabled: false,
            },
            dashStyle: "Dash",
            data: 
            // For a single data point, we want to use the trick that was used
            // in fake line earlier.
            isSingleDataPoint
                ? [
                    [
                        new Date(props.healthScoreTrend[0].dateTime).getTime() - 1,
                        Math.floor(industryBenchmark * 100),
                    ],
                    [
                        new Date(props.healthScoreTrend[0].dateTime).getTime() + 1,
                        Math.floor(industryBenchmark * 100),
                    ],
                ]
                : // For multiple data points, we want to select a first and last data points and
                    // give them industry benchmark values. This trick guarantees visual and x axis
                    // label to be cohesive.
                    [
                        props.healthScoreTrend[0],
                        props.healthScoreTrend[props.healthScoreTrend.length - 1],
                    ].map((t) => {
                        return [
                            new Date(t.dateTime).getTime(),
                            Math.floor(industryBenchmark * 100),
                        ];
                    }),
        });
    }
    return (_jsx("div", { style: { height: "100%" }, children: _jsx(HighchartsReact, { containerProps: { style: { height: "100%" } }, highcharts: Highcharts, options: options }) }));
}
function getUrlFromPoint(point) {
    if (doesPointHaveUrl(point)) {
        return point.url;
    }
    return;
}
function doesPointHaveUrl(point) {
    return point.hasOwnProperty("url");
}
