import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "highcharts/modules/accessibility";
export function SmallHealthScoreTrend(props) {
    var _a, _b, _c, _d, _e;
    const theme = useTheme();
    const isSingleDataPoint = props.healthScoreTrend.length === 1;
    const lastValue = props.healthScoreTrend[props.healthScoreTrend.length - 1];
    const firstValue = props.healthScoreTrend[0];
    const options = {
        exporting: {
            enabled: false,
        },
        chart: {
            spacing: [5, 0, 5, 0],
            type: "spline",
        },
        xAxis: {
            type: "datetime",
            visible: false,
            min: ((_a = firstValue === null || firstValue === void 0 ? void 0 : firstValue.dateTime) !== null && _a !== void 0 ? _a : 0) - 1,
            max: ((_b = lastValue === null || lastValue === void 0 ? void 0 : lastValue.dateTime) !== null && _b !== void 0 ? _b : 0) + 1,
        },
        yAxis: {
            title: undefined,
            visible: false,
            min: 0,
            // In theory, 100 is the max health score.
            // 100.5 is here to make sure that the line is not cut off
            // when the health score is 100.
            max: 100.5,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                },
            },
        },
        title: undefined,
        credits: {
            enabled: false,
        },
        series: [],
    };
    const industryBenchmark = props.industryBenchmark;
    const color = (industryBenchmark !== null && industryBenchmark !== void 0 ? industryBenchmark : 0) > (lastValue === null || lastValue === void 0 ? void 0 : lastValue.value)
        ? theme.palette.red[500]
        : theme.palette.green[500];
    const fillColor = (industryBenchmark !== null && industryBenchmark !== void 0 ? industryBenchmark : 0) > (lastValue === null || lastValue === void 0 ? void 0 : lastValue.value)
        ? theme.palette.red[200]
        : theme.palette.green[200];
    // If a trend has only one data point, we want to show a line on the same
    // level as the marker. This can be achieved by adding additional
    // serie to the array that has fake data points. One is before
    // the real data point, and the other one is after.
    // This way also makes it easy to modify interaction with the line.
    if (isSingleDataPoint) {
        // eslint-disable-next-line fp/no-mutating-methods
        (_c = options.series) === null || _c === void 0 ? void 0 : _c.push({
            type: "area",
            dataLabels: { enabled: false },
            lineWidth: 2,
            color: !industryBenchmark ? theme.palette.healthScoreChart.color : color,
            fillColor: !industryBenchmark
                ? theme.palette.healthScoreChart.fillColor
                : fillColor,
            marker: { enabled: false },
            label: {
                enabled: false,
            },
            showInLegend: false,
            enableMouseTracking: false,
            data: [
                {
                    x: props.healthScoreTrend[0].dateTime - 1,
                    y: Math.floor(props.healthScoreTrend[0].value * 100),
                },
                {
                    x: props.healthScoreTrend[0].dateTime + 1,
                    y: Math.floor(props.healthScoreTrend[0].value * 100),
                },
            ],
        });
    }
    // eslint-disable-next-line fp/no-mutating-methods
    (_d = options.series) === null || _d === void 0 ? void 0 : _d.push({
        type: "area",
        dataLabels: { enabled: false },
        color: !industryBenchmark ? theme.palette.healthScoreChart.color : color,
        fillColor: !industryBenchmark
            ? theme.palette.healthScoreChart.fillColor
            : fillColor,
        enableMouseTracking: false,
        marker: isSingleDataPoint
            ? { radius: 4, symbol: "circle", enabled: true }
            : { enabled: false },
        data: props.healthScoreTrend.map((t) => {
            return [t.dateTime, Math.floor(t.value * 100)];
        }),
    });
    // Industry benchmark dash line is a faked serie using
    // date time from the original series.
    if (industryBenchmark) {
        // eslint-disable-next-line fp/no-mutating-methods
        (_e = options.series) === null || _e === void 0 ? void 0 : _e.push({
            type: "line",
            dataLabels: { enabled: false },
            color: theme.palette.grey[700],
            lineWidth: 2,
            label: {
                enabled: false,
            },
            enableMouseTracking: false,
            marker: {
                enabled: false,
            },
            dashStyle: "Dash",
            data: 
            // For a single data point, we want to use the trick that was used
            // in fake line earlier.
            isSingleDataPoint
                ? [
                    [
                        props.healthScoreTrend[0].dateTime - 1,
                        Math.floor(industryBenchmark * 100),
                    ],
                    [
                        props.healthScoreTrend[0].dateTime + 1,
                        Math.floor(industryBenchmark * 100),
                    ],
                ]
                : // For multiple data points, we want to select a first and last data points and
                    // give them industry benchmark values. This trick guarantees visual and x axis
                    // label to be cohesive.
                    [
                        props.healthScoreTrend[0],
                        props.healthScoreTrend[props.healthScoreTrend.length - 1],
                    ].map((t) => {
                        return [t.dateTime, Math.floor(industryBenchmark * 100)];
                    }),
        });
    }
    return (_jsx("div", { style: { height: "100%" }, children: _jsx(HighchartsReact, { containerProps: { style: { height: "100%" } }, highcharts: Highcharts, options: options }) }));
}
